import request from '@/utils/request'


// 查询新-品牌弹窗设置列表
export function listPop(query) {
  return request({
    url: '/cigarette/brand-pop/list',
    method: 'get',
    params: query
  })
}

// 查询新-品牌弹窗设置分页
export function pagePop(query) {
  return request({
    url: '/cigarette/brand-pop/page',
    method: 'get',
    params: query
  })
}

// 查询新-品牌弹窗设置详细
export function getPop(data) {
  return request({
    url: '/cigarette/brand-pop/detail',
    method: 'get',
    params: data
  })
}

// 新增新-品牌弹窗设置
export function addPop(data) {
  return request({
    url: '/cigarette/brand-pop/add',
    method: 'post',
    data: data
  })
}

// 修改新-品牌弹窗设置
export function updatePop(data) {
  return request({
    url: '/cigarette/brand-pop/edit',
    method: 'post',
    data: data
  })
}

// 删除新-品牌弹窗设置
export function delPop(data) {
  return request({
    url: '/cigarette/brand-pop/delete',
    method: 'post',
    data: data
  })
}
